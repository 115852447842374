import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var LifBanUserMessageRsp = function LifBanUserMessageRsp() {
  this.iCode = 0;
  this.lForbidUid = 0;
  this.lRoomId = 0;
};
LifBanUserMessageRsp.prototype._clone = function () {
  return new Nimo.LifBanUserMessageRsp();
};
LifBanUserMessageRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LifBanUserMessageRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LifBanUserMessageRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt64(1, this.lForbidUid);
  os.writeInt64(2, this.lRoomId);
};
LifBanUserMessageRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.lForbidUid = is.readInt64(1, false, this.lForbidUid);
  this.lRoomId = is.readInt64(2, false, this.lRoomId);
};
Nimo.LifBanUserMessageRsp = LifBanUserMessageRsp;
export default LifBanUserMessageRsp;