import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var MsgRichTextType = function MsgRichTextType() {
  this.sPic = "";
  this.sHead = "";
  this.sBody = "";
  this.sJumpUrl = "";
};
MsgRichTextType.prototype._clone = function () {
  return new Nimo.MsgRichTextType();
};
MsgRichTextType.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgRichTextType.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgRichTextType.prototype.writeTo = function (os) {
  os.writeString(0, this.sPic);
  os.writeString(1, this.sHead);
  os.writeString(2, this.sBody);
  os.writeString(3, this.sJumpUrl);
};
MsgRichTextType.prototype.readFrom = function (is) {
  this.sPic = is.readString(0, false, this.sPic);
  this.sHead = is.readString(1, false, this.sHead);
  this.sBody = is.readString(2, false, this.sBody);
  this.sJumpUrl = is.readString(3, false, this.sJumpUrl);
};
Nimo.MsgRichTextType = MsgRichTextType;
export default MsgRichTextType;