import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var AnchorLevelUpMsgReadReq = function AnchorLevelUpMsgReadReq() {
  this.tUser = new Nimo.UserId();
  this.vMsgIDs = new Taf.Vector(new Taf.INT64());
};
AnchorLevelUpMsgReadReq.prototype._clone = function () {
  return new Nimo.AnchorLevelUpMsgReadReq();
};
AnchorLevelUpMsgReadReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelUpMsgReadReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelUpMsgReadReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeVector(1, this.vMsgIDs);
};
AnchorLevelUpMsgReadReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.vMsgIDs = is.readVector(1, false, this.vMsgIDs);
};
Nimo.AnchorLevelUpMsgReadReq = AnchorLevelUpMsgReadReq;
export default AnchorLevelUpMsgReadReq;