import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
import './MarkReadSession';
var MarkReadBatchReq = function MarkReadBatchReq() {
  this.tUserId = new Nimo.UserId();
  this.vSessions = new Taf.Vector(new Nimo.MarkReadSession());
};
MarkReadBatchReq.prototype._clone = function () {
  return new Nimo.MarkReadBatchReq();
};
MarkReadBatchReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MarkReadBatchReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MarkReadBatchReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeVector(1, this.vSessions);
};
MarkReadBatchReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.vSessions = is.readVector(1, false, this.vSessions);
};
Nimo.MarkReadBatchReq = MarkReadBatchReq;
export default MarkReadBatchReq;