import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var CreateMsgGroupReq = function CreateMsgGroupReq() {
  this.tUserId = new Nimo.UserId();
  this.sTitle = "";
  this.sDescribe = "";
  this.vUids = new Taf.Vector(new Taf.INT64());
  this.iAuthType = 0;
};
CreateMsgGroupReq.prototype._clone = function () {
  return new Nimo.CreateMsgGroupReq();
};
CreateMsgGroupReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CreateMsgGroupReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CreateMsgGroupReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeString(1, this.sTitle);
  os.writeString(2, this.sDescribe);
  os.writeVector(3, this.vUids);
  os.writeInt32(4, this.iAuthType);
};
CreateMsgGroupReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.sTitle = is.readString(1, false, this.sTitle);
  this.sDescribe = is.readString(2, false, this.sDescribe);
  this.vUids = is.readVector(3, false, this.vUids);
  this.iAuthType = is.readInt32(4, false, this.iAuthType);
};
Nimo.CreateMsgGroupReq = CreateMsgGroupReq;
export default CreateMsgGroupReq;