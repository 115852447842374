import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var UpdateMsgGroupInfoReq = function UpdateMsgGroupInfoReq() {
  this.tUserId = new Nimo.UserId();
  this.lMsgGroupId = 0;
  this.sTitle = "";
  this.sDescribe = "";
  this.iJoinType = 0;
  this.mAttributes = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
UpdateMsgGroupInfoReq.prototype._clone = function () {
  return new Nimo.UpdateMsgGroupInfoReq();
};
UpdateMsgGroupInfoReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UpdateMsgGroupInfoReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UpdateMsgGroupInfoReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lMsgGroupId);
  os.writeString(2, this.sTitle);
  os.writeString(3, this.sDescribe);
  os.writeInt32(4, this.iJoinType);
  os.writeMap(5, this.mAttributes);
};
UpdateMsgGroupInfoReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lMsgGroupId = is.readInt64(1, false, this.lMsgGroupId);
  this.sTitle = is.readString(2, false, this.sTitle);
  this.sDescribe = is.readString(3, false, this.sDescribe);
  this.iJoinType = is.readInt32(4, false, this.iJoinType);
  this.mAttributes = is.readMap(5, false, this.mAttributes);
};
Nimo.UpdateMsgGroupInfoReq = UpdateMsgGroupInfoReq;
export default UpdateMsgGroupInfoReq;