import NimoSailActivity from "../nimosailactivity";
import { Taf } from "@nimo-fed/taf";
import './QueryShareRewardInfo';
var ShareRewardInfoRsp = function ShareRewardInfoRsp() {
  this.iCode = 0;
  this.rewards = new Taf.Map(new Taf.INT32(), new Taf.Vector(new NimoSailActivity.QueryShareRewardInfo()));
};
ShareRewardInfoRsp.prototype._clone = function () {
  return new NimoSailActivity.ShareRewardInfoRsp();
};
ShareRewardInfoRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ShareRewardInfoRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ShareRewardInfoRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeMap(1, this.rewards);
};
ShareRewardInfoRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.rewards = is.readMap(1, false, this.rewards);
};
NimoSailActivity.ShareRewardInfoRsp = ShareRewardInfoRsp;
export default ShareRewardInfoRsp;