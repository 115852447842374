import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var MarkReadSession = function MarkReadSession() {
  this.lId = 0;
  this.lLatestMsgId = 0;
};
MarkReadSession.prototype._clone = function () {
  return new Nimo.MarkReadSession();
};
MarkReadSession.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MarkReadSession.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MarkReadSession.prototype.writeTo = function (os) {
  os.writeInt64(1, this.lId);
  os.writeInt64(2, this.lLatestMsgId);
};
MarkReadSession.prototype.readFrom = function (is) {
  this.lId = is.readInt64(1, false, this.lId);
  this.lLatestMsgId = is.readInt64(2, false, this.lLatestMsgId);
};
Nimo.MarkReadSession = MarkReadSession;
export default MarkReadSession;