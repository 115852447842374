import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var AnchorPrivilegeType = {
  kANCHOR_PRIVILEGE_TYPE_ROOM_ADMIN: 0,
  kANCHOR_PRIVILEGE_TYPE_LIVE_START_COVER: 1,
  kANCHOR_PRIVILEGE_TYPE_PAGE_TEMPLATE: 2,
  kANCHOR_PRIVILEGE_TYPE_LOTTERY: 3,
  KANCHOR_PRIVILEGE_TYPE_EXCLUSIVE_ROOM: 4,
  KANCHOR_PRIVILEGE_TYPE_FAN_CLUB: 5,
  kANCHOR_PRIVILEGE_TYPE_QUIZ: 6,
  kANCHOR_PRIVILEGE_TYPE_PERSONALIZED_URL: 7,
  kANCHOR_PRIVILEGE_TYPE_LIVE_REPLAY: 8,
  kANCHOR_PRIVILEGE_TYPE_TRANSCODE: 9,
  kANCHOR_PRIVILEGE_TYPE_CLIP_REPLAY: 10,
  kANCHOR_PRIVILEGE_TYPE_PICKME: 11,
  kANCHOR_PRIVILEGE_TYPE_ALERTBOX: 12,
  kANCHOR_PRIVILEGE_TYPE_LIVE_PREVIEW: 13,
  kANCHOR_PRIVILEGE_TYPE_FORWARD_PUSH: 14,
  kANCHOR_PRIVILEGE_TYPE_CHAT_BOT: 15,
  kANCHOR_PRIVILEGE_TYPE_BINDING_THIRD_PARTY_MEDIA: 16,
  kANCHOR_PRIVILEGE_TYPE_SAVE_TIME: 17,
  kANCHOR_PRIVILEGE_TYPE_FANSONLY: 18,
  kANCHOR_PRIVILEGE_TYPE_BIRTHDAY: 19,
  kANCHOR_PRIVILEGE_TYPE_ALERTBOX_TEXT_TO_SPEECH: 20
};
Nimo.AnchorPrivilegeType = AnchorPrivilegeType;
export default AnchorPrivilegeType;