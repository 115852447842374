import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './MsgSession';
var SessionHistoryRsp = function SessionHistoryRsp() {
  this.vMsgSession = new Taf.Vector(new Nimo.MsgSession());
  this.sSyncKey = "";
  this.bOver = true;
};
SessionHistoryRsp.prototype._clone = function () {
  return new Nimo.SessionHistoryRsp();
};
SessionHistoryRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SessionHistoryRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SessionHistoryRsp.prototype.writeTo = function (os) {
  os.writeVector(0, this.vMsgSession);
  os.writeString(1, this.sSyncKey);
  os.writeBoolean(2, this.bOver);
};
SessionHistoryRsp.prototype.readFrom = function (is) {
  this.vMsgSession = is.readVector(0, false, this.vMsgSession);
  this.sSyncKey = is.readString(1, false, this.sSyncKey);
  this.bOver = is.readBoolean(2, false, this.bOver);
};
Nimo.SessionHistoryRsp = SessionHistoryRsp;
export default SessionHistoryRsp;