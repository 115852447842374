import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var RemoveMsgGroupMemberReq = function RemoveMsgGroupMemberReq() {
  this.tUserId = new Nimo.UserId();
  this.lMsgGroupId = 0;
  this.lRemoveUid = 0;
  this.vRemoveUids = new Taf.Vector(new Taf.INT64());
};
RemoveMsgGroupMemberReq.prototype._clone = function () {
  return new Nimo.RemoveMsgGroupMemberReq();
};
RemoveMsgGroupMemberReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RemoveMsgGroupMemberReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RemoveMsgGroupMemberReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lMsgGroupId);
  os.writeInt64(2, this.lRemoveUid);
  os.writeVector(3, this.vRemoveUids);
};
RemoveMsgGroupMemberReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lMsgGroupId = is.readInt64(1, false, this.lMsgGroupId);
  this.lRemoveUid = is.readInt64(2, false, this.lRemoveUid);
  this.vRemoveUids = is.readVector(3, false, this.vRemoveUids);
};
Nimo.RemoveMsgGroupMemberReq = RemoveMsgGroupMemberReq;
export default RemoveMsgGroupMemberReq;