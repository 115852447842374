import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var AddMsgBlackListReq = function AddMsgBlackListReq() {
  this.tUserId = new Nimo.UserId();
  this.lUid = 0;
};
AddMsgBlackListReq.prototype._clone = function () {
  return new Nimo.AddMsgBlackListReq();
};
AddMsgBlackListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AddMsgBlackListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AddMsgBlackListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lUid);
};
AddMsgBlackListReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lUid = is.readInt64(1, false, this.lUid);
};
Nimo.AddMsgBlackListReq = AddMsgBlackListReq;
export default AddMsgBlackListReq;