import NimoSailActivity from "../nimosailactivity";
import { Taf } from "@nimo-fed/taf";
var ShareRewardInfoReq = function ShareRewardInfoReq() {
  this.sLang = "";
  this.udbUserId = 0;
};
ShareRewardInfoReq.prototype._clone = function () {
  return new NimoSailActivity.ShareRewardInfoReq();
};
ShareRewardInfoReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ShareRewardInfoReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ShareRewardInfoReq.prototype.writeTo = function (os) {
  os.writeString(0, this.sLang);
  os.writeInt64(1, this.udbUserId);
};
ShareRewardInfoReq.prototype.readFrom = function (is) {
  this.sLang = is.readString(0, false, this.sLang);
  this.udbUserId = is.readInt64(1, false, this.udbUserId);
};
NimoSailActivity.ShareRewardInfoReq = ShareRewardInfoReq;
export default ShareRewardInfoReq;