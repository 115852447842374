import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var GetVoiceRoomEmojiListReq = function GetVoiceRoomEmojiListReq() {
  this.tUser = new Nimo.UserId();
  this.lAnchorId = 0;
};
GetVoiceRoomEmojiListReq.prototype._clone = function () {
  return new Nimo.GetVoiceRoomEmojiListReq();
};
GetVoiceRoomEmojiListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetVoiceRoomEmojiListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetVoiceRoomEmojiListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt64(1, this.lAnchorId);
};
GetVoiceRoomEmojiListReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.lAnchorId = is.readInt64(1, false, this.lAnchorId);
};
Nimo.GetVoiceRoomEmojiListReq = GetVoiceRoomEmojiListReq;
export default GetVoiceRoomEmojiListReq;