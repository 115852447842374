import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var GetAnchorPanelInfoReq = function GetAnchorPanelInfoReq() {
  this.user = new Nimo.UserId();
};
GetAnchorPanelInfoReq.prototype._clone = function () {
  return new Nimo.GetAnchorPanelInfoReq();
};
GetAnchorPanelInfoReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetAnchorPanelInfoReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetAnchorPanelInfoReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
};
GetAnchorPanelInfoReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
};
Nimo.GetAnchorPanelInfoReq = GetAnchorPanelInfoReq;
export default GetAnchorPanelInfoReq;