import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var SetMessageFansOnlyModeReq = function SetMessageFansOnlyModeReq() {
  this.tUser = new Nimo.UserId();
  this.lRoomId = 0;
  this.iModeSwitch = 0;
  this.iLimitValue = 0;
};
SetMessageFansOnlyModeReq.prototype._clone = function () {
  return new Nimo.SetMessageFansOnlyModeReq();
};
SetMessageFansOnlyModeReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SetMessageFansOnlyModeReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SetMessageFansOnlyModeReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt64(1, this.lRoomId);
  os.writeInt32(2, this.iModeSwitch);
  os.writeInt32(3, this.iLimitValue);
};
SetMessageFansOnlyModeReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.iModeSwitch = is.readInt32(2, false, this.iModeSwitch);
  this.iLimitValue = is.readInt32(3, false, this.iLimitValue);
};
Nimo.SetMessageFansOnlyModeReq = SetMessageFansOnlyModeReq;
export default SetMessageFansOnlyModeReq;