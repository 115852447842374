import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var SetMessageFansOnlyModeRsp = function SetMessageFansOnlyModeRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
};
SetMessageFansOnlyModeRsp.prototype._clone = function () {
  return new Nimo.SetMessageFansOnlyModeRsp();
};
SetMessageFansOnlyModeRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SetMessageFansOnlyModeRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SetMessageFansOnlyModeRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
};
SetMessageFansOnlyModeRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
};
Nimo.SetMessageFansOnlyModeRsp = SetMessageFansOnlyModeRsp;
export default SetMessageFansOnlyModeRsp;