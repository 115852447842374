import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _mapMsgTypeToStructur, _LOG_VAL_RECEIVE_STRA, _LOG_VAL_REMIDER, _SPECIAL_NOTICE_USER_;
import memoize from 'memoize-one';
import { unpackCommon } from '@client/common/lib/nm-socket';
import { flatData } from '@client/common/lib/nm-taf';
import MsgCommType from '@client/jce/Nimo/MsgCommType';
import ESessionType from '@client/jce/Nimo/ESessionType';
import MsgPicType from '@client/jce/Nimo/MsgPicType';
import MsgGroupNoticeType from '@client/jce/Nimo/MsgGroupNoticeType';
import MsgNoticeType from '@client/jce/Nimo/MsgNoticeType';
import MsgRichTextType from '@client/jce/Nimo/MsgRichTextType';
import EMsgDataType from '@client/jce/Nimo/EMsgDataType';
import EOfficialID from '@client/jce/Nimo/EOfficialID';
import EMsgGroupMemberChangeType from '@client/jce/Nimo/EMsgGroupMemberChangeType';
import { replaceLangVars } from '@client/common/utils/string';
import MsgItem from '@client/jce/Nimo/MsgItem.js';
import { parseURL, getTheme } from '@client/common/utils/fn';
import groupAvatar from './img/group-avatar.png';
import groupAvatarDark from './img/group-avatar-dark.png';
import nimoAvatar from './img/nimo-avatar.jpg';
import sysAvatar from './img/sys-avatar.jpg';
import streamerAvatar from './img/streamer-server-avatar.jpg';
import history from '@client/common/services/react-history';
import removeUrlParams from '@client/common/utils/removeUrlParams';
import { session } from '@client/common/utils/storage';
import { twoEndOrigin } from '@client/common/services/url';
import ESpecialNoticeUserRole from '@client/jce/Nimo/ESpecialNoticeUserRole';
export var imGroupUrlReg = new RegExp("(https?://)?(www|m)(-[0-9A-Za-z]+)?.nimo.tv/msg[0-9a-zA-Z-_#+%@/\\&=~?.:|]*" // 限定 /msg 路径
);
export var imNimoUrlReg = new RegExp("(https?://)?(www|m|article|s3-static|s3-pre-static)(-[0-9A-Za-z]+)?.nimo.tv/?[0-9a-zA-Z-_#+%@/\\&=~?.:|]*",
// 包括 /msg 路径、模版系统活动页
'g');
export var httpProtocalReg = /^https?:\/\//;
var mapMsgTypeToStructure = (_mapMsgTypeToStructur = {}, _defineProperty(_mapMsgTypeToStructur, EMsgDataType.EMSGDATATYPE_COMM, MsgCommType), _defineProperty(_mapMsgTypeToStructur, EMsgDataType.EMSGDATATYPE_PICTURE, MsgPicType), _defineProperty(_mapMsgTypeToStructur, EMsgDataType.EMSGDATATYPE_RICHTEXT, MsgRichTextType), _defineProperty(_mapMsgTypeToStructur, EMsgDataType.EMSGDATATYPE_GROUPNOTICE, MsgGroupNoticeType), _defineProperty(_mapMsgTypeToStructur, EMsgDataType.EMSGDATATYPE_NOTICE, MsgNoticeType), _defineProperty(_mapMsgTypeToStructur, EMsgDataType.EMSGDATATYPE_SHARE, MsgCommType), _defineProperty(_mapMsgTypeToStructur, EMsgDataType.EMSGDATATYPE_SHARE_LIVE, MsgCommType), _defineProperty(_mapMsgTypeToStructur, EMsgDataType.EMSGDATATYPE_SHARE_VIDEO, MsgCommType), _defineProperty(_mapMsgTypeToStructur, EMsgDataType.EMSGDATATYPE_SHARE_POST, MsgCommType), _defineProperty(_mapMsgTypeToStructur, EMsgDataType.EMSGDATATYPE_CARD, MsgCommType), _mapMsgTypeToStructur);
export function unpackMessage(item) {
  var vData = item.vData;
  var Structure = mapMsgTypeToStructure[item.iDataType];
  return Structure ? flatData(unpackCommon(vData.buffer, Structure, '')) : undefined;
}
export function isPicMsg(item) {
  return item.iDataType === EMsgDataType.EMSGDATATYPE_PICTURE;
}
export function getMsgItemInstance(_ref) {
  var _ref$msg = _ref.msg,
    msg = _ref$msg === void 0 ? '' : _ref$msg,
    _ref$pic = _ref.pic,
    pic = _ref$pic === void 0 ? '' : _ref$pic,
    timeTag = _ref.timeTag;
  var msgItem = new MsgItem();
  if (msg) {
    msgItem.iDataType = EMsgDataType.EMSGDATATYPE_COMM;
    msgItem.sBody = msg;
  } else if (pic) {
    msgItem.iDataType = EMsgDataType.EMSGDATATYPE_PICTURE;
    msgItem.sPic = pic;
  }
  msgItem.lClientMsgId = timeTag;
  msgItem.lTime = Date.now();
  return msgItem;
}
export function getGroupIdFromUrl() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.href;
  var urlGroupId = null;
  if (!imGroupUrlReg.test(url)) {
    return urlGroupId;
  }
  var _ref2 = parseURL(url) || {},
    _ref2$pathArray = _ref2.pathArray,
    pathArray = _ref2$pathArray === void 0 ? [] : _ref2$pathArray;
  // 解析获取url的组ID
  if (pathArray.length === 2) {
    var targetStr = pathArray[1];
    if (/^-\d*$/.test(targetStr)) {
      urlGroupId = targetStr;
    } else {
      urlGroupId = false; // 标识组ID非法，与初始值 null 区分
    }
  }

  return urlGroupId;
}
export var SYSYEM_SESSION = 0;
export var NIMO_SESSION = -1;
export var STREAMER_SESSION = -2;
export var isOfficalSession = function isOfficalSession(data) {
  var _ref3 = data || {},
    iSessionType = _ref3.iSessionType;
  return ~[ESessionType.EAssit, ESessionType.ESysNotify, ESessionType.EOfficialComm, ESessionType.EOfficial].indexOf(iSessionType);
};
export var isFeatureSession = function isFeatureSession(data) {
  var _ref4 = data || {},
    lId = _ref4.lId;
  return [EOfficialID.EFOLLOW, EOfficialID.EIDAt, EOfficialID.EIDLikeNotify, EOfficialID.EIDCommentNotify].includes(lId);
};
export var getDefaultAvatar = function getDefaultAvatar(lId) {
  if (+lId === SYSYEM_SESSION) {
    return sysAvatar;
  } else if (+lId === NIMO_SESSION) {
    return nimoAvatar;
  } else if (+lId === STREAMER_SESSION) {
    return streamerAvatar;
  } else if (+lId < 0) {
    return +getTheme() === 1 ? groupAvatar : groupAvatarDark;
  } else {
    return '';
  }
};
export var getGroupNoticeLangMap = memoize(function (langPkg) {
  var _ref5;
  return _ref5 = {}, _defineProperty(_ref5, EMsgGroupMemberChangeType.EMSGGROUP_JOIN, langPkg === null || langPkg === void 0 ? void 0 : langPkg.joinGroupTips), _defineProperty(_ref5, EMsgGroupMemberChangeType.EMSGGROUP_EXIT, langPkg === null || langPkg === void 0 ? void 0 : langPkg.exitGroupTips), _defineProperty(_ref5, EMsgGroupMemberChangeType.EMSGGROUP_KICK, langPkg === null || langPkg === void 0 ? void 0 : langPkg.removeSomeoneTips), _defineProperty(_ref5, EMsgGroupMemberChangeType.EMSGGROUP_INIITED, langPkg === null || langPkg === void 0 ? void 0 : langPkg.inviteSomeoneTips), _ref5;
});
export var getGroupNoticeMsg = function getGroupNoticeMsg(_ref6, langPkg) {
  var sOpNick = _ref6.sOpNick,
    vUserNick = _ref6.vUserNick,
    iType = _ref6.iType,
    sText = _ref6.sText;
  var text = getGroupNoticeLangMap(langPkg)[iType] || '';
  var sUserNick = vUserNick.join('、');
  if (iType === EMsgGroupMemberChangeType.EMSGGROUP_TEXT) {
    text = sText;
  } else if (~[EMsgGroupMemberChangeType.EMSGGROUP_JOIN, EMsgGroupMemberChangeType.EMSGGROUP_EXIT].indexOf(iType)) {
    text = replaceLangVars(text, [sOpNick]);
  } else {
    text = replaceLangVars(text, [sOpNick, sUserNick]);
  }
  return text;
};
export var MSG_STATUS_SUCCESS = 0; // 发送成功
export var MSG_STATUS_FAIL = 1; // 发送失败
export var MSG_STATUS_LOADING = 2; // loading

export var sessionKey = '_NIMO_IM_CHAT_';
var _ref7 = parseURL() || {},
  params = _ref7.params;
var targetChart = +params.chat || null;
var chatType = params.type || '';
if (targetChart && new RegExp("".concat(twoEndOrigin, "/msg")).test(window.location.href)) {
  history.replace(removeUrlParams(['chat', 'type']).replace(window.location.origin, '')); // 删除queryparams
}

var sessionChatData = null;
if (targetChart) {
  try {
    var data = JSON.parse(session.get(sessionKey) || '');
    if (+(data === null || data === void 0 ? void 0 : data.lId) === +targetChart) {
      sessionChatData = data;
    }
  } catch (error) {}
}
export var getChartData = function getChartData() {
  return {
    targetChart: targetChart,
    sessionChatData: sessionChatData,
    chatType: chatType
  };
};
export var clearSessionData = function clearSessionData() {
  sessionChatData = null;
  session.remove(sessionKey);
};
export var ERcvrStrangerMsg = {
  ENotReceive: '0',
  EReceive: '1'
};
export var EStrangerSpecialNotice = {
  EStrangerSpecialNoticeNone: '0',
  EStrangerSpecialNoticeFans: '1',
  EStrangerSpecialNoticeAllRoyal: '2',
  EStrangerSpecialNoticeVIPRoyal3: '3'
};
export var LOG_VAL_RECEIVE_STRANGER = (_LOG_VAL_RECEIVE_STRA = {}, _defineProperty(_LOG_VAL_RECEIVE_STRA, ERcvrStrangerMsg.ENotReceive, 'close'), _defineProperty(_LOG_VAL_RECEIVE_STRA, ERcvrStrangerMsg.EReceive, 'open'), _LOG_VAL_RECEIVE_STRA);
export var LOG_VAL_REMIDER = (_LOG_VAL_REMIDER = {}, _defineProperty(_LOG_VAL_REMIDER, EStrangerSpecialNotice.EStrangerSpecialNoticeNone, 'nolimit'), _defineProperty(_LOG_VAL_REMIDER, EStrangerSpecialNotice.EStrangerSpecialNoticeFans, 'fanclub'), _defineProperty(_LOG_VAL_REMIDER, EStrangerSpecialNotice.EStrangerSpecialNoticeAllRoyal, 'all_royal'), _defineProperty(_LOG_VAL_REMIDER, EStrangerSpecialNotice.EStrangerSpecialNoticeVIPRoyal3, 'above_duke'), _LOG_VAL_REMIDER);
export { ESpecialNoticeUserRole };
var None = ESpecialNoticeUserRole.None,
  Fans = ESpecialNoticeUserRole.Fans,
  AllRoyal = ESpecialNoticeUserRole.AllRoyal,
  VIPRoyal3 = ESpecialNoticeUserRole.VIPRoyal3;
export var SPECIAL_NOTICE_USER_ROLE_REPORT = (_SPECIAL_NOTICE_USER_ = {}, _defineProperty(_SPECIAL_NOTICE_USER_, None, 'nolimit'), _defineProperty(_SPECIAL_NOTICE_USER_, Fans, 'fanclub'), _defineProperty(_SPECIAL_NOTICE_USER_, AllRoyal, 'all_royal'), _defineProperty(_SPECIAL_NOTICE_USER_, VIPRoyal3, 'above_duke'), _SPECIAL_NOTICE_USER_);