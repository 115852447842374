import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var MsgMarkReadNotify = function MsgMarkReadNotify() {
  this.lId = 0;
  this.lLatestMsgId = 0;
};
MsgMarkReadNotify.prototype._clone = function () {
  return new Nimo.MsgMarkReadNotify();
};
MsgMarkReadNotify.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgMarkReadNotify.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgMarkReadNotify.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lId);
  os.writeInt64(1, this.lLatestMsgId);
};
MsgMarkReadNotify.prototype.readFrom = function (is) {
  this.lId = is.readInt64(0, false, this.lId);
  this.lLatestMsgId = is.readInt64(1, false, this.lLatestMsgId);
};
Nimo.MsgMarkReadNotify = MsgMarkReadNotify;
export default MsgMarkReadNotify;