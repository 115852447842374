import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './MessageNotice';
var SendMessageRsp = function SendMessageRsp() {
  this.iStatus = 0;
  this.tNotice = new Nimo.MessageNotice();
  this.reason = 0;
  this.retTime = 0;
  this.illWord = "";
  this.iLimitTime = 0;
  this.iCode = 0;
  this.sErrorMsg = "";
  this.mExtraData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
SendMessageRsp.prototype._clone = function () {
  return new Nimo.SendMessageRsp();
};
SendMessageRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SendMessageRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SendMessageRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iStatus);
  os.writeStruct(1, this.tNotice);
  os.writeInt32(2, this.reason);
  os.writeInt32(3, this.retTime);
  os.writeString(4, this.illWord);
  os.writeInt32(5, this.iLimitTime);
  os.writeInt32(6, this.iCode);
  os.writeString(7, this.sErrorMsg);
  os.writeMap(8, this.mExtraData);
};
SendMessageRsp.prototype.readFrom = function (is) {
  this.iStatus = is.readInt32(0, false, this.iStatus);
  this.tNotice = is.readStruct(1, false, this.tNotice);
  this.reason = is.readInt32(2, false, this.reason);
  this.retTime = is.readInt32(3, false, this.retTime);
  this.illWord = is.readString(4, false, this.illWord);
  this.iLimitTime = is.readInt32(5, false, this.iLimitTime);
  this.iCode = is.readInt32(6, false, this.iCode);
  this.sErrorMsg = is.readString(7, false, this.sErrorMsg);
  this.mExtraData = is.readMap(8, false, this.mExtraData);
};
Nimo.SendMessageRsp = SendMessageRsp;
export default SendMessageRsp;