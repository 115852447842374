import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var ExitMsgGroupReq = function ExitMsgGroupReq() {
  this.tUserId = new Nimo.UserId();
  this.lMsgGroupId = 0;
};
ExitMsgGroupReq.prototype._clone = function () {
  return new Nimo.ExitMsgGroupReq();
};
ExitMsgGroupReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ExitMsgGroupReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ExitMsgGroupReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lMsgGroupId);
};
ExitMsgGroupReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lMsgGroupId = is.readInt64(1, false, this.lMsgGroupId);
};
Nimo.ExitMsgGroupReq = ExitMsgGroupReq;
export default ExitMsgGroupReq;