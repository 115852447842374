import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var VoiceRoomBanRecord = function VoiceRoomBanRecord() {
  this.lRoomId = 0;
  this.lUid = 0;
  this.lOperatorUid = 0;
  this.lCreateTime = 0;
  this.lUpdateTime = 0;
  this.lDeadline = 0;
  this.lRemainTime = 0;
  this.lUserNimoId = 0;
  this.sUserNick = "";
  this.sUserAvatarUrl = "";
};
VoiceRoomBanRecord.prototype._clone = function () {
  return new Nimo.VoiceRoomBanRecord();
};
VoiceRoomBanRecord.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VoiceRoomBanRecord.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VoiceRoomBanRecord.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt64(1, this.lUid);
  os.writeInt64(2, this.lOperatorUid);
  os.writeInt64(3, this.lCreateTime);
  os.writeInt64(4, this.lUpdateTime);
  os.writeInt64(5, this.lDeadline);
  os.writeInt64(6, this.lRemainTime);
  os.writeInt64(7, this.lUserNimoId);
  os.writeString(8, this.sUserNick);
  os.writeString(9, this.sUserAvatarUrl);
};
VoiceRoomBanRecord.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.lOperatorUid = is.readInt64(2, false, this.lOperatorUid);
  this.lCreateTime = is.readInt64(3, false, this.lCreateTime);
  this.lUpdateTime = is.readInt64(4, false, this.lUpdateTime);
  this.lDeadline = is.readInt64(5, false, this.lDeadline);
  this.lRemainTime = is.readInt64(6, false, this.lRemainTime);
  this.lUserNimoId = is.readInt64(7, false, this.lUserNimoId);
  this.sUserNick = is.readString(8, false, this.sUserNick);
  this.sUserAvatarUrl = is.readString(9, false, this.sUserAvatarUrl);
};
Nimo.VoiceRoomBanRecord = VoiceRoomBanRecord;
export default VoiceRoomBanRecord;