import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var GetChatRuleRsp = function GetChatRuleRsp() {
  this.udbUserId = 0;
  this.chatRuleStatus = 0;
  this.chatRuleText = "";
};
GetChatRuleRsp.prototype._clone = function () {
  return new NimoBuss.GetChatRuleRsp();
};
GetChatRuleRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetChatRuleRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetChatRuleRsp.prototype.writeTo = function (os) {
  os.writeInt64(0, this.udbUserId);
  os.writeInt32(1, this.chatRuleStatus);
  os.writeString(2, this.chatRuleText);
};
GetChatRuleRsp.prototype.readFrom = function (is) {
  this.udbUserId = is.readInt64(0, false, this.udbUserId);
  this.chatRuleStatus = is.readInt32(1, false, this.chatRuleStatus);
  this.chatRuleText = is.readString(2, false, this.chatRuleText);
};
NimoBuss.GetChatRuleRsp = GetChatRuleRsp;
export default GetChatRuleRsp;