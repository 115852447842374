import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var ViolateParam = function ViolateParam() {
  this.baseParam = new NimoBuss.BaseParam();
};
ViolateParam.prototype._clone = function () {
  return new NimoBuss.ViolateParam();
};
ViolateParam.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ViolateParam.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ViolateParam.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
};
ViolateParam.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
};
NimoBuss.ViolateParam = ViolateParam;
export default ViolateParam;