import NimoSailActivity from "../nimosailactivity";
import { Taf } from "@nimo-fed/taf";
var QueryShareRewardInfo = function QueryShareRewardInfo() {
  this.name = "";
  this.num = 0;
  this.icon = "";
  this.value = 0;
  this.giftId = 0;
  this.awardType = 0;
};
QueryShareRewardInfo.prototype._clone = function () {
  return new NimoSailActivity.QueryShareRewardInfo();
};
QueryShareRewardInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
QueryShareRewardInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
QueryShareRewardInfo.prototype.writeTo = function (os) {
  os.writeString(0, this.name);
  os.writeInt32(1, this.num);
  os.writeString(2, this.icon);
  os.writeInt32(3, this.value);
  os.writeInt32(4, this.giftId);
  os.writeInt32(5, this.awardType);
};
QueryShareRewardInfo.prototype.readFrom = function (is) {
  this.name = is.readString(0, false, this.name);
  this.num = is.readInt32(1, false, this.num);
  this.icon = is.readString(2, false, this.icon);
  this.value = is.readInt32(3, false, this.value);
  this.giftId = is.readInt32(4, false, this.giftId);
  this.awardType = is.readInt32(5, false, this.awardType);
};
NimoSailActivity.QueryShareRewardInfo = QueryShareRewardInfo;
export default QueryShareRewardInfo;