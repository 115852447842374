import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './MsgGroupMemberInfo';
var GetMsgGroupMemberInfoListRsp = function GetMsgGroupMemberInfoListRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
  this.vMemberList = new Taf.Vector(new Nimo.MsgGroupMemberInfo());
};
GetMsgGroupMemberInfoListRsp.prototype._clone = function () {
  return new Nimo.GetMsgGroupMemberInfoListRsp();
};
GetMsgGroupMemberInfoListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetMsgGroupMemberInfoListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetMsgGroupMemberInfoListRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
  os.writeVector(2, this.vMemberList);
};
GetMsgGroupMemberInfoListRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
  this.vMemberList = is.readVector(2, false, this.vMemberList);
};
Nimo.GetMsgGroupMemberInfoListRsp = GetMsgGroupMemberInfoListRsp;
export default GetMsgGroupMemberInfoListRsp;