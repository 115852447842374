import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var DelSessionBatchReq = function DelSessionBatchReq() {
  this.tUserId = new Nimo.UserId();
  this.vSessionIds = new Taf.Vector(new Taf.INT64());
};
DelSessionBatchReq.prototype._clone = function () {
  return new Nimo.DelSessionBatchReq();
};
DelSessionBatchReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
DelSessionBatchReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
DelSessionBatchReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeVector(1, this.vSessionIds);
};
DelSessionBatchReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.vSessionIds = is.readVector(1, false, this.vSessionIds);
};
Nimo.DelSessionBatchReq = DelSessionBatchReq;
export default DelSessionBatchReq;