import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var CheckMsgBlackListReq = function CheckMsgBlackListReq() {
  this.tUserId = new Nimo.UserId();
  this.lUid = 0;
};
CheckMsgBlackListReq.prototype._clone = function () {
  return new Nimo.CheckMsgBlackListReq();
};
CheckMsgBlackListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CheckMsgBlackListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CheckMsgBlackListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lUid);
};
CheckMsgBlackListReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lUid = is.readInt64(1, false, this.lUid);
};
Nimo.CheckMsgBlackListReq = CheckMsgBlackListReq;
export default CheckMsgBlackListReq;