import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './MsgSession';
var MsgSessionRsp = function MsgSessionRsp() {
  this.tMsgSession = new Nimo.MsgSession();
  this.sSyncKey = "";
  this.bOver = true;
};
MsgSessionRsp.prototype._clone = function () {
  return new Nimo.MsgSessionRsp();
};
MsgSessionRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgSessionRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgSessionRsp.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tMsgSession);
  os.writeString(1, this.sSyncKey);
  os.writeBoolean(2, this.bOver);
};
MsgSessionRsp.prototype.readFrom = function (is) {
  this.tMsgSession = is.readStruct(0, false, this.tMsgSession);
  this.sSyncKey = is.readString(1, false, this.sSyncKey);
  this.bOver = is.readBoolean(2, false, this.bOver);
};
Nimo.MsgSessionRsp = MsgSessionRsp;
export default MsgSessionRsp;