import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './BlackWhiteListMatchInfo';
var BlackWhiteListResult = function BlackWhiteListResult() {
  this.bInBlack = false;
  this.bInWhite = false;
  this.bHasPermission = false;
  this.sMessage = "";
  this.lEndTimestamp = 0;
  this.sAttribute = "";
  this.tMatchInfo = new Nimo.BlackWhiteListMatchInfo();
};
BlackWhiteListResult.prototype._clone = function () {
  return new Nimo.BlackWhiteListResult();
};
BlackWhiteListResult.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BlackWhiteListResult.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BlackWhiteListResult.prototype.writeTo = function (os) {
  os.writeBoolean(0, this.bInBlack);
  os.writeBoolean(1, this.bInWhite);
  os.writeBoolean(2, this.bHasPermission);
  os.writeString(3, this.sMessage);
  os.writeInt64(4, this.lEndTimestamp);
  os.writeString(5, this.sAttribute);
  os.writeStruct(6, this.tMatchInfo);
};
BlackWhiteListResult.prototype.readFrom = function (is) {
  this.bInBlack = is.readBoolean(0, false, this.bInBlack);
  this.bInWhite = is.readBoolean(1, false, this.bInWhite);
  this.bHasPermission = is.readBoolean(2, false, this.bHasPermission);
  this.sMessage = is.readString(3, false, this.sMessage);
  this.lEndTimestamp = is.readInt64(4, false, this.lEndTimestamp);
  this.sAttribute = is.readString(5, false, this.sAttribute);
  this.tMatchInfo = is.readStruct(6, false, this.tMatchInfo);
};
Nimo.BlackWhiteListResult = BlackWhiteListResult;
export default BlackWhiteListResult;