import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var MsgSendReq = function MsgSendReq() {
  this.tUserId = new Nimo.UserId();
  this.lRcvrUid = 0;
  this.iDataType = 0;
  this.vData = new Taf.BinBuffer();
  this.lClientMsgId = 0;
  this.iReqVer = 0;
  this.sReqSource = "";
  this.vAtUids = new Taf.Vector(new Taf.INT64());
  this.iSendOfflinePush = 1;
  this.iNotifcationType = 1;
  this.iNoticeMsgType = 0;
};
MsgSendReq.prototype._clone = function () {
  return new Nimo.MsgSendReq();
};
MsgSendReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgSendReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgSendReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserId);
  os.writeInt64(1, this.lRcvrUid);
  os.writeInt32(2, this.iDataType);
  os.writeBytes(3, this.vData);
  os.writeInt64(4, this.lClientMsgId);
  os.writeInt32(5, this.iReqVer);
  os.writeString(6, this.sReqSource);
  os.writeVector(7, this.vAtUids);
  os.writeInt32(8, this.iSendOfflinePush);
  os.writeInt32(9, this.iNotifcationType);
  os.writeInt32(11, this.iNoticeMsgType);
};
MsgSendReq.prototype.readFrom = function (is) {
  this.tUserId = is.readStruct(0, false, this.tUserId);
  this.lRcvrUid = is.readInt64(1, false, this.lRcvrUid);
  this.iDataType = is.readInt32(2, false, this.iDataType);
  this.vData = is.readBytes(3, false, this.vData);
  this.lClientMsgId = is.readInt64(4, false, this.lClientMsgId);
  this.iReqVer = is.readInt32(5, false, this.iReqVer);
  this.sReqSource = is.readString(6, false, this.sReqSource);
  this.vAtUids = is.readVector(7, false, this.vAtUids);
  this.iSendOfflinePush = is.readInt32(8, false, this.iSendOfflinePush);
  this.iNotifcationType = is.readInt32(9, false, this.iNotifcationType);
  this.iNoticeMsgType = is.readInt32(11, false, this.iNoticeMsgType);
};
Nimo.MsgSendReq = MsgSendReq;
export default MsgSendReq;