import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './FollowListData';
import './PageControlView';
var FollowAllListView = function FollowAllListView() {
  this.totalCount = 0;
  this.offLiveCount = 0;
  this.onLiveCount = 0;
  this.list = new Taf.Vector(new NimoBuss.FollowListData());
  this.pageControlView = new NimoBuss.PageControlView();
};
FollowAllListView.prototype._clone = function () {
  return new NimoBuss.FollowAllListView();
};
FollowAllListView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FollowAllListView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FollowAllListView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.totalCount);
  os.writeInt32(1, this.offLiveCount);
  os.writeInt32(2, this.onLiveCount);
  os.writeVector(3, this.list);
  os.writeStruct(4, this.pageControlView);
};
FollowAllListView.prototype.readFrom = function (is) {
  this.totalCount = is.readInt32(0, false, this.totalCount);
  this.offLiveCount = is.readInt32(1, false, this.offLiveCount);
  this.onLiveCount = is.readInt32(2, false, this.onLiveCount);
  this.list = is.readVector(3, false, this.list);
  this.pageControlView = is.readStruct(4, false, this.pageControlView);
};
NimoBuss.FollowAllListView = FollowAllListView;
export default FollowAllListView;