import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ChatBanRecord = function ChatBanRecord() {
  this.lRoomId = 0;
  this.lUid = 0;
  this.lOperatorUid = 0;
  this.lCreateTime = 0;
  this.lUpdateTime = 0;
  this.lDeadline = 0;
  this.lRemainTime = 0;
};
ChatBanRecord.prototype._clone = function () {
  return new Nimo.ChatBanRecord();
};
ChatBanRecord.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ChatBanRecord.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ChatBanRecord.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt64(1, this.lUid);
  os.writeInt64(2, this.lOperatorUid);
  os.writeInt64(3, this.lCreateTime);
  os.writeInt64(4, this.lUpdateTime);
  os.writeInt64(5, this.lDeadline);
  os.writeInt64(6, this.lRemainTime);
};
ChatBanRecord.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.lOperatorUid = is.readInt64(2, false, this.lOperatorUid);
  this.lCreateTime = is.readInt64(3, false, this.lCreateTime);
  this.lUpdateTime = is.readInt64(4, false, this.lUpdateTime);
  this.lDeadline = is.readInt64(5, false, this.lDeadline);
  this.lRemainTime = is.readInt64(6, false, this.lRemainTime);
};
Nimo.ChatBanRecord = ChatBanRecord;
export default ChatBanRecord;