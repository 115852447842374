import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './RegionView';
var AreaView = function AreaView() {
  this.id = 0;
  this.name = "";
  this.regionViewList = new Taf.Vector(new NimoBuss.RegionView());
  this.regionCode = "";
};
AreaView.prototype._clone = function () {
  return new NimoBuss.AreaView();
};
AreaView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AreaView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AreaView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.id);
  os.writeString(1, this.name);
  os.writeVector(2, this.regionViewList);
  os.writeString(3, this.regionCode);
};
AreaView.prototype.readFrom = function (is) {
  this.id = is.readInt32(0, false, this.id);
  this.name = is.readString(1, false, this.name);
  this.regionViewList = is.readVector(2, false, this.regionViewList);
  this.regionCode = is.readString(3, false, this.regionCode);
};
NimoBuss.AreaView = AreaView;
export default AreaView;