import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var CheckUserStatusInRoomRsp = function CheckUserStatusInRoomRsp() {
  this.iCode = 0;
  this.mStatusInfo = new Taf.Map(new Taf.INT32(), new Taf.BinBuffer());
  this.lNimoId = 0;
  this.lUDBId = 0;
  this.sNick = "";
  this.sAvatar = "";
};
CheckUserStatusInRoomRsp.prototype._clone = function () {
  return new Nimo.CheckUserStatusInRoomRsp();
};
CheckUserStatusInRoomRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CheckUserStatusInRoomRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CheckUserStatusInRoomRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeMap(1, this.mStatusInfo);
  os.writeInt64(2, this.lNimoId);
  os.writeInt64(3, this.lUDBId);
  os.writeString(4, this.sNick);
  os.writeString(5, this.sAvatar);
};
CheckUserStatusInRoomRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.mStatusInfo = is.readMap(1, false, this.mStatusInfo);
  this.lNimoId = is.readInt64(2, false, this.lNimoId);
  this.lUDBId = is.readInt64(3, false, this.lUDBId);
  this.sNick = is.readString(4, false, this.sNick);
  this.sAvatar = is.readString(5, false, this.sAvatar);
};
Nimo.CheckUserStatusInRoomRsp = CheckUserStatusInRoomRsp;
export default CheckUserStatusInRoomRsp;