import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './MsgGroupMemberInfo';
var GetMsgGroupInfoRsp = function GetMsgGroupInfoRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
  this.lMsgGroupId = 0;
  this.sTitle = "";
  this.sDescribe = "";
  this.lGroupOwnerUid = 0;
  this.sShareInviteURL = "";
  this.sGroupOwnerNick = "";
  this.iGroupMemberCount = 0;
  this.iJoinType = 0;
  this.iFollowType = 0;
  this.lGroupOwnerNimoId = 0;
  this.sGroupOwnerAvatar = "";
  this.vGroupUserInfo = new Taf.Vector(new Nimo.MsgGroupMemberInfo());
  this.iAuthType = 0;
};
GetMsgGroupInfoRsp.prototype._clone = function () {
  return new Nimo.GetMsgGroupInfoRsp();
};
GetMsgGroupInfoRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetMsgGroupInfoRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetMsgGroupInfoRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
  os.writeInt64(2, this.lMsgGroupId);
  os.writeString(3, this.sTitle);
  os.writeString(4, this.sDescribe);
  os.writeInt64(5, this.lGroupOwnerUid);
  os.writeString(6, this.sShareInviteURL);
  os.writeString(7, this.sGroupOwnerNick);
  os.writeInt32(8, this.iGroupMemberCount);
  os.writeInt32(9, this.iJoinType);
  os.writeInt32(10, this.iFollowType);
  os.writeInt64(11, this.lGroupOwnerNimoId);
  os.writeString(12, this.sGroupOwnerAvatar);
  os.writeVector(13, this.vGroupUserInfo);
  os.writeInt32(14, this.iAuthType);
};
GetMsgGroupInfoRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
  this.lMsgGroupId = is.readInt64(2, false, this.lMsgGroupId);
  this.sTitle = is.readString(3, false, this.sTitle);
  this.sDescribe = is.readString(4, false, this.sDescribe);
  this.lGroupOwnerUid = is.readInt64(5, false, this.lGroupOwnerUid);
  this.sShareInviteURL = is.readString(6, false, this.sShareInviteURL);
  this.sGroupOwnerNick = is.readString(7, false, this.sGroupOwnerNick);
  this.iGroupMemberCount = is.readInt32(8, false, this.iGroupMemberCount);
  this.iJoinType = is.readInt32(9, false, this.iJoinType);
  this.iFollowType = is.readInt32(10, false, this.iFollowType);
  this.lGroupOwnerNimoId = is.readInt64(11, false, this.lGroupOwnerNimoId);
  this.sGroupOwnerAvatar = is.readString(12, false, this.sGroupOwnerAvatar);
  this.vGroupUserInfo = is.readVector(13, false, this.vGroupUserInfo);
  this.iAuthType = is.readInt32(14, false, this.iAuthType);
};
Nimo.GetMsgGroupInfoRsp = GetMsgGroupInfoRsp;
export default GetMsgGroupInfoRsp;