import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './MessageNotice';
import './SendItemSubBroadcastPacket';
import './PayChatNotice';
var GetRoomHistoryMsgInfo = function GetRoomHistoryMsgInfo() {
  this.type = 0;
  this.tContentMsg = new Nimo.MessageNotice();
  this.tContentGift = new Nimo.SendItemSubBroadcastPacket();
  this.tContentPayChat = new Nimo.PayChatNotice();
};
GetRoomHistoryMsgInfo.prototype._clone = function () {
  return new Nimo.GetRoomHistoryMsgInfo();
};
GetRoomHistoryMsgInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetRoomHistoryMsgInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetRoomHistoryMsgInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.type);
  os.writeStruct(1, this.tContentMsg);
  os.writeStruct(2, this.tContentGift);
  os.writeStruct(3, this.tContentPayChat);
};
GetRoomHistoryMsgInfo.prototype.readFrom = function (is) {
  this.type = is.readInt32(0, false, this.type);
  this.tContentMsg = is.readStruct(1, false, this.tContentMsg);
  this.tContentGift = is.readStruct(2, false, this.tContentGift);
  this.tContentPayChat = is.readStruct(3, false, this.tContentPayChat);
};
Nimo.GetRoomHistoryMsgInfo = GetRoomHistoryMsgInfo;
export default GetRoomHistoryMsgInfo;