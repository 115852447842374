import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ChangeSessionMuteStatusRsp = function ChangeSessionMuteStatusRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
  this.lSessionId = 0;
};
ChangeSessionMuteStatusRsp.prototype._clone = function () {
  return new Nimo.ChangeSessionMuteStatusRsp();
};
ChangeSessionMuteStatusRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ChangeSessionMuteStatusRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ChangeSessionMuteStatusRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
  os.writeInt64(2, this.lSessionId);
};
ChangeSessionMuteStatusRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
  this.lSessionId = is.readInt64(2, false, this.lSessionId);
};
Nimo.ChangeSessionMuteStatusRsp = ChangeSessionMuteStatusRsp;
export default ChangeSessionMuteStatusRsp;