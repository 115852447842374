import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './AvatarBox';
var UserInfo = function UserInfo() {
  this.lUserId = 0;
  this.lUidLocal = 0;
  this.sNickName = "";
  this.bIsPresentner = false;
  this.sAvatarUrl = "";
  this.iSex = 0;
  this.sCountryCode = "";
  this.lCreateTimeMs = 0;
  this.bIsRoomManager = false;
  this.lLastLoginTimeMs = 0;
  this.iDatatime = 0;
  this.lLastLoginDeviceId = 0;
  this.iAccountType = 0;
  this.iLcid = 0;
  this.iRelationShip = 0;
  this.iAuthenticated = 0;
  this.sAvatarBoxUrl = "";
  this.iStatus = 0;
  this.sPersonalizedId = "";
  this.sDynamicAvatarBoxUrl = "";
  this.sBio = "";
  this.tAvatarBox = new Nimo.AvatarBox();
  this.iPersonalizedLevel = 0;
  this.lBirthday = 0;
  this.iIsMysteryMan = 0;
};
UserInfo.prototype._clone = function () {
  return new Nimo.UserInfo();
};
UserInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUserId);
  os.writeInt64(1, this.lUidLocal);
  os.writeString(2, this.sNickName);
  os.writeBoolean(3, this.bIsPresentner);
  os.writeString(4, this.sAvatarUrl);
  os.writeInt32(5, this.iSex);
  os.writeString(6, this.sCountryCode);
  os.writeInt64(7, this.lCreateTimeMs);
  os.writeBoolean(8, this.bIsRoomManager);
  os.writeInt64(9, this.lLastLoginTimeMs);
  os.writeInt32(10, this.iDatatime);
  os.writeInt64(11, this.lLastLoginDeviceId);
  os.writeInt32(12, this.iAccountType);
  os.writeInt32(13, this.iLcid);
  os.writeInt32(14, this.iRelationShip);
  os.writeInt32(15, this.iAuthenticated);
  os.writeString(16, this.sAvatarBoxUrl);
  os.writeInt32(17, this.iStatus);
  os.writeString(18, this.sPersonalizedId);
  os.writeString(19, this.sDynamicAvatarBoxUrl);
  os.writeString(20, this.sBio);
  os.writeStruct(21, this.tAvatarBox);
  os.writeInt32(22, this.iPersonalizedLevel);
  os.writeInt64(23, this.lBirthday);
  os.writeInt32(24, this.iIsMysteryMan);
};
UserInfo.prototype.readFrom = function (is) {
  this.lUserId = is.readInt64(0, false, this.lUserId);
  this.lUidLocal = is.readInt64(1, false, this.lUidLocal);
  this.sNickName = is.readString(2, false, this.sNickName);
  this.bIsPresentner = is.readBoolean(3, false, this.bIsPresentner);
  this.sAvatarUrl = is.readString(4, false, this.sAvatarUrl);
  this.iSex = is.readInt32(5, false, this.iSex);
  this.sCountryCode = is.readString(6, false, this.sCountryCode);
  this.lCreateTimeMs = is.readInt64(7, false, this.lCreateTimeMs);
  this.bIsRoomManager = is.readBoolean(8, false, this.bIsRoomManager);
  this.lLastLoginTimeMs = is.readInt64(9, false, this.lLastLoginTimeMs);
  this.iDatatime = is.readInt32(10, false, this.iDatatime);
  this.lLastLoginDeviceId = is.readInt64(11, false, this.lLastLoginDeviceId);
  this.iAccountType = is.readInt32(12, false, this.iAccountType);
  this.iLcid = is.readInt32(13, false, this.iLcid);
  this.iRelationShip = is.readInt32(14, false, this.iRelationShip);
  this.iAuthenticated = is.readInt32(15, false, this.iAuthenticated);
  this.sAvatarBoxUrl = is.readString(16, false, this.sAvatarBoxUrl);
  this.iStatus = is.readInt32(17, false, this.iStatus);
  this.sPersonalizedId = is.readString(18, false, this.sPersonalizedId);
  this.sDynamicAvatarBoxUrl = is.readString(19, false, this.sDynamicAvatarBoxUrl);
  this.sBio = is.readString(20, false, this.sBio);
  this.tAvatarBox = is.readStruct(21, false, this.tAvatarBox);
  this.iPersonalizedLevel = is.readInt32(22, false, this.iPersonalizedLevel);
  this.lBirthday = is.readInt64(23, false, this.lBirthday);
  this.iIsMysteryMan = is.readInt32(24, false, this.iIsMysteryMan);
};
Nimo.UserInfo = UserInfo;
export default UserInfo;