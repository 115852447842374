import NimoSailActivity from "../nimosailactivity";
import { Taf } from "@nimo-fed/taf";
var GetShareRewardReq = function GetShareRewardReq() {
  this.udbUserId = 0;
};
GetShareRewardReq.prototype._clone = function () {
  return new NimoSailActivity.GetShareRewardReq();
};
GetShareRewardReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetShareRewardReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetShareRewardReq.prototype.writeTo = function (os) {
  os.writeInt64(0, this.udbUserId);
};
GetShareRewardReq.prototype.readFrom = function (is) {
  this.udbUserId = is.readInt64(0, false, this.udbUserId);
};
NimoSailActivity.GetShareRewardReq = GetShareRewardReq;
export default GetShareRewardReq;