import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var MsgGroupNoticeType = function MsgGroupNoticeType() {
  this.sOpNick = "";
  this.vUserNick = new Taf.Vector(new Taf.STRING());
  this.iType = 0;
  this.vUids = new Taf.Vector(new Taf.INT64());
  this.sText = "";
  this.mAttribute = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
MsgGroupNoticeType.prototype._clone = function () {
  return new Nimo.MsgGroupNoticeType();
};
MsgGroupNoticeType.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgGroupNoticeType.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgGroupNoticeType.prototype.writeTo = function (os) {
  os.writeString(0, this.sOpNick);
  os.writeVector(1, this.vUserNick);
  os.writeInt32(2, this.iType);
  os.writeVector(3, this.vUids);
  os.writeString(4, this.sText);
  os.writeMap(5, this.mAttribute);
};
MsgGroupNoticeType.prototype.readFrom = function (is) {
  this.sOpNick = is.readString(0, false, this.sOpNick);
  this.vUserNick = is.readVector(1, false, this.vUserNick);
  this.iType = is.readInt32(2, false, this.iType);
  this.vUids = is.readVector(3, false, this.vUids);
  this.sText = is.readString(4, false, this.sText);
  this.mAttribute = is.readMap(5, false, this.mAttribute);
};
Nimo.MsgGroupNoticeType = MsgGroupNoticeType;
export default MsgGroupNoticeType;