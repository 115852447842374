import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var SetMessageSlowModeReq = function SetMessageSlowModeReq() {
  this.tUser = new Nimo.UserId();
  this.lRoomId = 0;
  this.iIntervalTimeS = 0;
};
SetMessageSlowModeReq.prototype._clone = function () {
  return new Nimo.SetMessageSlowModeReq();
};
SetMessageSlowModeReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SetMessageSlowModeReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SetMessageSlowModeReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt64(1, this.lRoomId);
  os.writeInt32(2, this.iIntervalTimeS);
};
SetMessageSlowModeReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.iIntervalTimeS = is.readInt32(2, false, this.iIntervalTimeS);
};
Nimo.SetMessageSlowModeReq = SetMessageSlowModeReq;
export default SetMessageSlowModeReq;