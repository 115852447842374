/* eslint-disable import/prefer-default-export */

export var penaltyCodeMap = {
  R1: {
    reason: 'reason16',
    level: ''
  },
  R2: {
    reason: 'reason17',
    level: ''
  },
  R3: {
    reason: 'reason18',
    level: ''
  },
  R4: {
    reason: 'reason19',
    level: ''
  },
  R5: {
    reason: 'reason20',
    level: ''
  },
  1: {
    reason: 'reason16',
    level: ''
  },
  2: {
    reason: 'reason17',
    level: ''
  },
  3: {
    reason: 'reason18',
    level: ''
  },
  4: {
    reason: 'reason19',
    level: ''
  },
  5: {
    reason: 'reason20',
    level: ''
  },
  A8001: {
    reason: 'reason1',
    level: 'level1'
  },
  A8002: {
    reason: 'reason2',
    level: 'level1'
  },
  A8003: {
    reason: 'reason3',
    level: 'level1'
  },
  A8004: {
    reason: 'reason4',
    level: 'level1'
  },
  A8005: {
    reason: 'reason5',
    level: 'level1'
  },
  A8006: {
    reason: 'reason6',
    level: 'level1'
  },
  A8007: {
    reason: 'reason7',
    level: 'level1'
  },
  A8008: {
    reason: 'reason8',
    level: 'level1'
  },
  A8009: {
    reason: 'reason9',
    level: 'level1'
  },
  A8010: {
    reason: 'reason10',
    level: 'level1'
  },
  A8013: {
    reason: 'reason13',
    // '着装要求',
    level: 'level1'
  },
  A8014: {
    reason: 'reason14',
    // '未成年',
    level: 'level1'
  },
  A8015: {
    reason: 'reason15',
    // '其他',
    level: 'level1'
  },
  B8001: {
    reason: 'reason1',
    level: 'level2'
  },
  B8002: {
    reason: 'reason2',
    level: 'level2'
  },
  B8005: {
    reason: 'reason5',
    level: 'level2'
  },
  B8007: {
    reason: 'reason7',
    level: 'level2'
  },
  B8008: {
    reason: 'reason8',
    level: 'level2'
  },
  B8009: {
    reason: 'reason9',
    level: 'level2'
  },
  B8010: {
    reason: 'reason10',
    level: 'level2'
  },
  B8011: {
    reason: 'reason11',
    // '侵犯他人合法权益',
    level: 'level2'
  },
  B8012: {
    reason: 'reason12',
    // '不良行为',
    level: 'level2'
  },
  B8013: {
    reason: 'reason13',
    // '着装要求',
    level: 'level2'
  },
  B8014: {
    reason: 'reason14',
    // '未成年',
    level: 'level2'
  },
  B8015: {
    reason: 'reason15',
    // '其他',
    level: 'level2'
  },
  B8018: {
    // 体育及电竞赛事侵权
    reason: 'reason7',
    level: 'level2'
  },
  B8019: {
    // 影视侵权
    reason: 'reason7',
    level: 'level2'
  },
  C8002: {
    reason: 'reason2',
    level: 'level3' // 'C级'
  },

  C8007: {
    reason: 'reason7',
    level: 'level3' // 'C级'
  },

  C8015: {
    reason: 'reason15',
    // '其他',
    level: 'level3' // 'C级'
  }
};

export var penaltyTypeMap = {
  1: 'type4',
  2: 'type5',
  3: 'type1'
};
export var penaltyLevelMap = {
  A: 'level1',
  B: 'level2',
  C: 'level3'
};
export var PENALTY_INFO = {
  BAN_LIVE: 2,
  // 禁播
  BAN_NEW_18: 18,
  // 新禁播弹窗
  BAN_NEW_19: 19,
  BAN_WARNING: 27 // 违规弹窗
};

/**
 * 获取《社区公约》地址
 */
export function getCommunityGuidance() {
  return "//".concat(DOMAINS.maindomain, "/p/agreement4");
}

/**
 * 获取优先级最高的处罚记录
 *
 * 处罚弹窗显示规则：
 * 1. 一次下发只显示优先级最高的弹窗
 * 2. priority 值越小，优先级越高
 */
export function getFirstPriority(data) {
  var _ref = data || {},
    content = _ref.transDownPacketRspContent;
  var info = {};
  if (content instanceof Array) {
    content.forEach(function (item) {
      if (info.priority === undefined || info.priority && item.priority < info.priority // 显示优先级比较高的一条信息
      ) {
        info = item;
      }
    });
  }
  return info;
}