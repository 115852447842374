import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './MsgItem';
var MsgSession = function MsgSession() {
  this.vMsgItem = new Taf.Vector(new Nimo.MsgItem());
  this.iNewMsgCount = 0;
  this.lId = 0;
  this.sTitle = "";
  this.sPic = "";
  this.iSessionType = 0;
  this.iRelationType = 0;
  this.lUidLocal = 0;
  this.iAuthenticated = 0;
  this.iRoyalLevel = 0;
  this.lLastSenderUid = 0;
  this.sLastSenderNick = "";
  this.vAtMsgIds = new Taf.Vector(new Taf.INT64());
  this.iNimoOfficial = 0;
  this.iMuteSession = 0;
  this.iAuthType = 0;
  this.iStrangerSettingNotice = 0;
  this.iDisplayArea = 0;
  this.iSpecialVipService = 0;
  this.iSpecialNoticeUser = 0;
};
MsgSession.prototype._clone = function () {
  return new Nimo.MsgSession();
};
MsgSession.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgSession.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgSession.prototype.writeTo = function (os) {
  os.writeVector(0, this.vMsgItem);
  os.writeInt32(1, this.iNewMsgCount);
  os.writeInt64(2, this.lId);
  os.writeString(3, this.sTitle);
  os.writeString(4, this.sPic);
  os.writeInt32(5, this.iSessionType);
  os.writeInt32(6, this.iRelationType);
  os.writeInt64(7, this.lUidLocal);
  os.writeInt32(8, this.iAuthenticated);
  os.writeInt32(9, this.iRoyalLevel);
  os.writeInt64(10, this.lLastSenderUid);
  os.writeString(11, this.sLastSenderNick);
  os.writeVector(12, this.vAtMsgIds);
  os.writeInt32(13, this.iNimoOfficial);
  os.writeInt32(14, this.iMuteSession);
  os.writeInt32(15, this.iAuthType);
  os.writeInt32(17, this.iStrangerSettingNotice);
  os.writeInt32(18, this.iDisplayArea);
  os.writeInt32(19, this.iSpecialVipService);
  os.writeInt32(20, this.iSpecialNoticeUser);
};
MsgSession.prototype.readFrom = function (is) {
  this.vMsgItem = is.readVector(0, false, this.vMsgItem);
  this.iNewMsgCount = is.readInt32(1, false, this.iNewMsgCount);
  this.lId = is.readInt64(2, false, this.lId);
  this.sTitle = is.readString(3, false, this.sTitle);
  this.sPic = is.readString(4, false, this.sPic);
  this.iSessionType = is.readInt32(5, false, this.iSessionType);
  this.iRelationType = is.readInt32(6, false, this.iRelationType);
  this.lUidLocal = is.readInt64(7, false, this.lUidLocal);
  this.iAuthenticated = is.readInt32(8, false, this.iAuthenticated);
  this.iRoyalLevel = is.readInt32(9, false, this.iRoyalLevel);
  this.lLastSenderUid = is.readInt64(10, false, this.lLastSenderUid);
  this.sLastSenderNick = is.readString(11, false, this.sLastSenderNick);
  this.vAtMsgIds = is.readVector(12, false, this.vAtMsgIds);
  this.iNimoOfficial = is.readInt32(13, false, this.iNimoOfficial);
  this.iMuteSession = is.readInt32(14, false, this.iMuteSession);
  this.iAuthType = is.readInt32(15, false, this.iAuthType);
  this.iStrangerSettingNotice = is.readInt32(17, false, this.iStrangerSettingNotice);
  this.iDisplayArea = is.readInt32(18, false, this.iDisplayArea);
  this.iSpecialVipService = is.readInt32(19, false, this.iSpecialVipService);
  this.iSpecialNoticeUser = is.readInt32(20, false, this.iSpecialNoticeUser);
};
Nimo.MsgSession = MsgSession;
export default MsgSession;