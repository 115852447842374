import NimoSailActivity from "../nimosailactivity";
import { Taf } from "@nimo-fed/taf";
var GetShareRewardRsp = function GetShareRewardRsp() {
  this.iCode = 0;
  this.status = 0;
  this.shareUserNum = 0;
  this.newUserNum = 0;
};
GetShareRewardRsp.prototype._clone = function () {
  return new NimoSailActivity.GetShareRewardRsp();
};
GetShareRewardRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetShareRewardRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetShareRewardRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt32(1, this.status);
  os.writeInt32(2, this.shareUserNum);
  os.writeInt32(3, this.newUserNum);
};
GetShareRewardRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.status = is.readInt32(1, false, this.status);
  this.shareUserNum = is.readInt32(2, false, this.shareUserNum);
  this.newUserNum = is.readInt32(3, false, this.newUserNum);
};
NimoSailActivity.GetShareRewardRsp = GetShareRewardRsp;
export default GetShareRewardRsp;