import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './RoomScreenshotsView';
import './AnchorLabelView';
import Nimo from '../nimo';
import '../Nimo/RoomLineInfo';
import './CoverLabelView';
import './MatchAnchorInfo';
import '../Nimo/MeetingInfo';
import './PkSummaryView';
import './TeamPKSummaryView';
var FollowListData = function FollowListData() {
  this.onLive = true;
  this.watchCount = 0;
  this.anchorName = "";
  this.anchorImage = "";
  this.roomName = "";
  this.typeLabel = "";
  this.roomID = 0;
  this.anchorID = 0;
  this.roomType = 0;
  this.businessType = 0;
  this.lastLiveOnFinishTime = 0;
  this.isFillInfo = true;
  this.fanCount = 0;
  this.alias = "";
  this.roomSort = 0;
  this.isLottery = 0;
  this.microPKStatus = 0;
  this.relationship = 0;
  this.authenticatedAnchor = 0;
  this.isPlayback = 0;
  this.watchTimeOfLast7Days = 0;
  this.roomImage = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.anchorLabels = new Taf.Vector(new NimoBuss.AnchorLabelView());
  this.roomLineInfo = new Nimo.RoomLineInfo();
  this.coverLabels = new Taf.Vector(new NimoBuss.CoverLabelView());
  this.avatarBoxUrl = "";
  this.superscriptType = 0;
  this.superscriptText = "";
  this.mStreamPkg = "";
  this.nextLiveNoticeTimestamp = 0;
  this.templateType = 0;
  this.dynamicAvatarBoxUrl = "";
  this.nextLiveNoticeEndTime = 0;
  this.nextLiveNoticeDesc = "";
  this.bookStatus = 0;
  this.matchStatus = 0;
  this.matchAnchorInfo = new NimoBuss.MatchAnchorInfo();
  this.iScene = 0;
  this.liveType = 0;
  this.meetingInfo = new Nimo.MeetingInfo();
  this.pkSummaryView = new NimoBuss.PkSummaryView();
  this.countryCode = "";
  this.teamPKSummaryView = new NimoBuss.TeamPKSummaryView();
};
FollowListData.prototype._clone = function () {
  return new NimoBuss.FollowListData();
};
FollowListData.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FollowListData.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FollowListData.prototype.writeTo = function (os) {
  os.writeBoolean(0, this.onLive);
  os.writeInt64(1, this.watchCount);
  os.writeString(2, this.anchorName);
  os.writeString(3, this.anchorImage);
  os.writeString(4, this.roomName);
  os.writeString(5, this.typeLabel);
  os.writeInt64(6, this.roomID);
  os.writeInt64(7, this.anchorID);
  os.writeInt64(8, this.roomType);
  os.writeInt32(9, this.businessType);
  os.writeInt32(10, this.lastLiveOnFinishTime);
  os.writeBoolean(11, this.isFillInfo);
  os.writeInt64(12, this.fanCount);
  os.writeString(13, this.alias);
  os.writeInt32(14, this.roomSort);
  os.writeInt32(15, this.isLottery);
  os.writeInt32(16, this.microPKStatus);
  os.writeInt32(17, this.relationship);
  os.writeInt32(18, this.authenticatedAnchor);
  os.writeInt32(19, this.isPlayback);
  os.writeInt64(20, this.watchTimeOfLast7Days);
  os.writeVector(21, this.roomImage);
  os.writeVector(22, this.anchorLabels);
  os.writeStruct(23, this.roomLineInfo);
  os.writeVector(24, this.coverLabels);
  os.writeString(25, this.avatarBoxUrl);
  os.writeInt32(26, this.superscriptType);
  os.writeString(27, this.superscriptText);
  os.writeString(28, this.mStreamPkg);
  os.writeInt64(29, this.nextLiveNoticeTimestamp);
  os.writeInt32(30, this.templateType);
  os.writeString(31, this.dynamicAvatarBoxUrl);
  os.writeInt64(32, this.nextLiveNoticeEndTime);
  os.writeString(33, this.nextLiveNoticeDesc);
  os.writeInt32(34, this.bookStatus);
  os.writeInt32(35, this.matchStatus);
  os.writeStruct(36, this.matchAnchorInfo);
  os.writeInt32(37, this.iScene);
  os.writeInt32(38, this.liveType);
  os.writeStruct(39, this.meetingInfo);
  os.writeStruct(40, this.pkSummaryView);
  os.writeString(41, this.countryCode);
  os.writeStruct(42, this.teamPKSummaryView);
};
FollowListData.prototype.readFrom = function (is) {
  this.onLive = is.readBoolean(0, false, this.onLive);
  this.watchCount = is.readInt64(1, false, this.watchCount);
  this.anchorName = is.readString(2, false, this.anchorName);
  this.anchorImage = is.readString(3, false, this.anchorImage);
  this.roomName = is.readString(4, false, this.roomName);
  this.typeLabel = is.readString(5, false, this.typeLabel);
  this.roomID = is.readInt64(6, false, this.roomID);
  this.anchorID = is.readInt64(7, false, this.anchorID);
  this.roomType = is.readInt64(8, false, this.roomType);
  this.businessType = is.readInt32(9, false, this.businessType);
  this.lastLiveOnFinishTime = is.readInt32(10, false, this.lastLiveOnFinishTime);
  this.isFillInfo = is.readBoolean(11, false, this.isFillInfo);
  this.fanCount = is.readInt64(12, false, this.fanCount);
  this.alias = is.readString(13, false, this.alias);
  this.roomSort = is.readInt32(14, false, this.roomSort);
  this.isLottery = is.readInt32(15, false, this.isLottery);
  this.microPKStatus = is.readInt32(16, false, this.microPKStatus);
  this.relationship = is.readInt32(17, false, this.relationship);
  this.authenticatedAnchor = is.readInt32(18, false, this.authenticatedAnchor);
  this.isPlayback = is.readInt32(19, false, this.isPlayback);
  this.watchTimeOfLast7Days = is.readInt64(20, false, this.watchTimeOfLast7Days);
  this.roomImage = is.readVector(21, false, this.roomImage);
  this.anchorLabels = is.readVector(22, false, this.anchorLabels);
  this.roomLineInfo = is.readStruct(23, false, this.roomLineInfo);
  this.coverLabels = is.readVector(24, false, this.coverLabels);
  this.avatarBoxUrl = is.readString(25, false, this.avatarBoxUrl);
  this.superscriptType = is.readInt32(26, false, this.superscriptType);
  this.superscriptText = is.readString(27, false, this.superscriptText);
  this.mStreamPkg = is.readString(28, false, this.mStreamPkg);
  this.nextLiveNoticeTimestamp = is.readInt64(29, false, this.nextLiveNoticeTimestamp);
  this.templateType = is.readInt32(30, false, this.templateType);
  this.dynamicAvatarBoxUrl = is.readString(31, false, this.dynamicAvatarBoxUrl);
  this.nextLiveNoticeEndTime = is.readInt64(32, false, this.nextLiveNoticeEndTime);
  this.nextLiveNoticeDesc = is.readString(33, false, this.nextLiveNoticeDesc);
  this.bookStatus = is.readInt32(34, false, this.bookStatus);
  this.matchStatus = is.readInt32(35, false, this.matchStatus);
  this.matchAnchorInfo = is.readStruct(36, false, this.matchAnchorInfo);
  this.iScene = is.readInt32(37, false, this.iScene);
  this.liveType = is.readInt32(38, false, this.liveType);
  this.meetingInfo = is.readStruct(39, false, this.meetingInfo);
  this.pkSummaryView = is.readStruct(40, false, this.pkSummaryView);
  this.countryCode = is.readString(41, false, this.countryCode);
  this.teamPKSummaryView = is.readStruct(42, false, this.teamPKSummaryView);
};
NimoBuss.FollowListData = FollowListData;
export default FollowListData;