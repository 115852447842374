import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './MsgSession';
var SessionDelNotify = function SessionDelNotify() {
  this.lUid = 0;
  this.sUA = "";
  this.tMsgSession = new Nimo.MsgSession();
};
SessionDelNotify.prototype._clone = function () {
  return new Nimo.SessionDelNotify();
};
SessionDelNotify.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SessionDelNotify.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SessionDelNotify.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeString(1, this.sUA);
  os.writeStruct(2, this.tMsgSession);
};
SessionDelNotify.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.sUA = is.readString(1, false, this.sUA);
  this.tMsgSession = is.readStruct(2, false, this.tMsgSession);
};
Nimo.SessionDelNotify = SessionDelNotify;
export default SessionDelNotify;