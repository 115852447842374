import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var EActionType = {
  ENoAct: 0,
  EJump: 1,
  ECustomer: 2,
  EJumpLive: 3,
  EJumpVideo: 4,
  EJumpClip: 5,
  EJumpScheme: 6,
  EDone: 7,
  EGroupUp: 8,
  EQuickAction: 9,
  EJumpMyBag: 10,
  EJumpLiveStart: 11,
  EPrivateChat: 12,
  EFollow: 13,
  EReplyComment: 14
};
Nimo.EActionType = EActionType;
export default EActionType;