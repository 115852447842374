import { cookie } from '@client/common/utils/storage';

// TODO:动态QR Code
export var PC_APPFLYER_TYPE_MAP = {
  pid: 'nimo_web',
  // pid
  TOP_APP_STORE: 'topappstore',
  // web导航-appstore
  TOP_GOOGLE_PLAY: 'topgp',
  // web导航-Google Play
  DOWNLOAD_APP_STORE: 'downloadappstore',
  // web下载页-appstore
  DOWNLOAD_GOOGLE_PLAY: 'downloadgp',
  // web下载页-Google Play
  SIDE_APP_STORE: 'sideappstore',
  // web侧边导航-appstore
  SIDE_GOOGLE_PLAY: 'sidegp' // web侧边导航-Google Play
};

var getPid = function getPid(type) {
  var pid;
  var arr = [PC_APPFLYER_TYPE_MAP];
  for (var index = 0; index < arr.length; index++) {
    var map = arr[index];
    if (Object.values(map).indexOf(type) !== -1) {
      pid = map.pid;
      break;
    }
  }
  return pid;
};
export default (function (type, marketType) {
  var url = "https://nimotv.onelink.me/B6cW?pid=".concat(getPid(type), "&c=").concat(type, "&af_dp=&af_dp=nimotv%3A%2F%2F");
  if (marketType === 'appStore') {
    url += '&af_web_dp=https%3A%2F%2Fitunes.apple.com%2Fid%2Fapp%2Fid1367087826%3Fmt%3D8%26';
  } else if (marketType === 'googlePlay') {
    url += '&af_web_dp=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.huya.nimo%26';
  }
  var afSub1 = '0';
  var afSub2 = 'direct';
  var afSub3 = 'other';
  var anchorActLink = cookie.get('ss_source_anchor_link_roomId');
  var ssSource = cookie.get('ss_source_ad_type') || cookie.get('ss_source');
  var currentRoomId = window.G_roomBaseInfo ? window.G_roomBaseInfo.roomId : null;
  if (anchorActLink) {
    afSub1 = "activity2_".concat(anchorActLink);
  }
  if (ssSource) {
    afSub2 = ssSource;
  }
  if (currentRoomId) {
    afSub3 = currentRoomId;
  }
  url += "&af_sub1=".concat(afSub1, "&af_sub2=").concat(afSub2, "&af_sub3=").concat(afSub3);
  return url;
});