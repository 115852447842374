import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './AnchorPrivilegeConfig';
var AnchorLevelUpMsg = function AnchorLevelUpMsg() {
  this.lMsgID = 0;
  this.iToLevel = 0;
  this.bNewPrivilege = true;
  this.vPrivilege = new Taf.Vector(new Nimo.AnchorPrivilegeConfig());
};
AnchorLevelUpMsg.prototype._clone = function () {
  return new Nimo.AnchorLevelUpMsg();
};
AnchorLevelUpMsg.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelUpMsg.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelUpMsg.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lMsgID);
  os.writeInt32(1, this.iToLevel);
  os.writeBoolean(2, this.bNewPrivilege);
  os.writeVector(3, this.vPrivilege);
};
AnchorLevelUpMsg.prototype.readFrom = function (is) {
  this.lMsgID = is.readInt64(0, false, this.lMsgID);
  this.iToLevel = is.readInt32(1, false, this.iToLevel);
  this.bNewPrivilege = is.readBoolean(2, false, this.bNewPrivilege);
  this.vPrivilege = is.readVector(3, false, this.vPrivilege);
};
Nimo.AnchorLevelUpMsg = AnchorLevelUpMsg;
export default AnchorLevelUpMsg;